<template>
  <cms-mega-menu-l1-desktop v-bind="forwarded">
    <template #category-link-inline v-if="$feature.tempHeaderVariation === 'basic'">
      <cms-shared-mega-menu-category-link class="w-1/6" class-link="gap-1 text-md fw-bold" :content />
    </template>
    <template #category-link-block v-if="$feature.tempHeaderVariation === 'basic'">
      <span />
    </template>
  </cms-mega-menu-l1-desktop>
</template>

<script lang="ts" setup>
import { useForwardPropsEmits } from 'radix-vue'
import CmsMegaMenuL1Desktop, { type Emits, type Props } from '#content/components/cms/mega-menu/MegaMenuL1Desktop.vue'

const props = defineProps<Props>()
const emits = defineEmits<Emits>()
const forwarded = useForwardPropsEmits(props, emits)
const { $feature } = useNuxtApp()
const config = useAppConfig().components.cms

if ($feature.tempHeaderVariation === 'basic') {
  config.megaMenuL1Desktop.triggerOnClick = false
  config.megaMenu.linkIcon = 'arrow'
  config.megaMenuL1Desktop.brandClasses.menu = 'bg-white px-6 pt-4 pb-8 b-b b-grey-80'
  config.megaMenuL1Desktop.brandClasses.popoverContent = 'pt-3'
  config.megaMenuL1Desktop.brandClasses.columns = 'mt-4'
}
</script>
